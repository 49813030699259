import React, { useEffect }  from "react";

function TransferToMainWeb() {
    const idBranch = localStorage.getItem('tokenHome');

    useEffect(()=> {
     //   window.open("https://azumayavietnam.com/","_self");
        if(idBranch) {
            window.open("https://azumayasurvey.com/survey/home/"+idBranch,"_self");
        } else {
            window.open("https://azumayavietnam.com/","_self");
        }
    })

    return (
        <></>
    )
}

export default TransferToMainWeb
