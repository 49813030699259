export const QUESTION_ROTEN = {
    jap: {
        title1: "東屋ホテルの露天風呂をご利用いただきありがとうございます。",
        title2: "今後のサービス改善のため、下記にご意見をいただけますと幸いです。",
        title3: "こちらの意見はマネジメント部門に直接届きます。",
        questionroomNumber: {
            title: "お部屋の番号を教えていただきませんか?",
            answer: "",
        },
        question1: {
            title: "",
            answer: ""
        }
    },
    eng: {
        title1: "Thank you for your staying at our Azumaya Hotel. ",
        title2: "We hope to listen your opinions to provide you the best service.",
        title3: "",
        questionroomNumber: {
            title: "Your room number?",
            answer: "",
        },
        question1: {
            title: "",
            answer: ""
        }
    }
}
